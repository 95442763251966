import React from "react"
import { graphql, Link } from "gatsby"
import SiteContainer from "../../components/site-container";
import { Helmet } from "react-helmet";
import PostContent from "../../components/PostContent";

export default function Blog({ data, location }) {
  return (
    <SiteContainer location={location}>
      <Helmet>
        <title>News and Articles from Firstparty HQ</title>
        <meta name="description" content="Follow the Firstparty blog to learn about new product features, company announcements, and the latest in first-party cookie usage for developers and marketers." />
        <link rel='stylesheet' id='wp-block-library-css' href='https://wordpress.firstpartyhq.com/wp-includes/css/dist/block-library/style.min.css?ver=5.8' type='text/css' media='all' />
        
      </Helmet>

      {data.allWpPost.edges.map(({ node }) => (
        <PostContent post={node} link={true} />
      ))}
    </SiteContainer>
  )
}
export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          excerpt
          content
          slug
          seo {
            fullHead
            title
          }
          date
          author {
            node {
              name
              avatar {
                  default
                  extraAttr
                  forceDefault
                  foundAvatar
                  height
                  rating
                  scheme
                  size
                  url
                  width
                }
            }
          }
        }
      }
    }
  }
`